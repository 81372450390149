@font-face {
    font-family: 'Geo_Arial';
    src: url('GeoArialItalic.woff2') format('woff2'),
        url('GeoArialItalic.woff') format('woff'),
        url('GeoArialItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial GEO';
    src: url('ArialGEOItalic.woff2') format('woff2'),
        url('ArialGEOItalic.woff') format('woff'),
        url('ArialGEOItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arial GEO';
    src: url('ArialGEOBold.woff2') format('woff2'),
        url('ArialGEOBold.woff') format('woff'),
        url('ArialGEOBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Narrow';
    src: url('ArialNarrow.woff2') format('woff2'),
        url('ArialNarrow.woff') format('woff'),
        url('ArialNarrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial Th';
    src: url('ArialTh.woff2') format('woff2'),
        url('ArialTh.woff') format('woff'),
        url('ArialTh.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial MT';
    src: url('ArialMTBlack.woff2') format('woff2'),
        url('ArialMTBlack.woff') format('woff'),
        url('ArialMTBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

