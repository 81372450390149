.App {
    font-family: sans-serif;
    text-align: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .progress-container {
    width: 250px;
    color: #363a43;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 19px;
    font-weight: normal;
    font-size: 14px;
    color: #363a43;
  }
  
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
  .upload-list {
    display: flex;
    gap: 10px;
    align-items: center;
  
    border: 0.1rem solid #cfdbde;
    border-radius: 0.2rem;
    padding: 10px;
    width: 100%;
  }
  .file-size {
    color: #7eb631;
  }
  
  #upload-button {
    color: black;
    padding: 0.5rem 1rem;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    border:1px solid;
    border-style:dotted;
    border-width: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }