@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #fff;
  overflow: hidden;
}
.leftmenu .MuiListItemIcon-root.black_text {
  min-width: fit-content;
}

.painnation .MuiTablePagination-toolbar .MuiTablePagination-actions button.MuiIconButton-sizeMedium:hover {
  background-color: #1b83db;
}
.painnation .MuiTablePagination-toolbar p {
  margin-bottom: 0;
}


.btn.butclose {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 26px;
  height: 49px;
  width: 49px;
}
.backbut button {
  font-size: 15px;
  padding: 8px 28px;
  font-weight: 500;
  border-radius: 6px;
  width: 250px;
}
.backbut {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}

.alleidertbut{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
}

.alleidertbut button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #0076f4;
}

.puchbuton{
  text-align: center;

}

canvas,
.canvas-container {
  margin-bottom: 25px;
}
.addbutne {
  background-color: #2699fb!important;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Arial';
  border-radius: 4px;
}
.canvas-container {
  margin-left: auto;
  margin-right: auto;
}
.tool i.fa {
  color: #2699fb;
}
.toolbar {
  width: 100%;
  border: 1px solid #707070;
  height: 55px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    padding-left: 5px;
}
.splited_pdf_file.m-2 {
  width: 170px;
}
.toolbar .tool button.boxshodw {
  box-shadow: none;
}
#pdf-container {
  background-color: #888888;
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: fit-content;
}
#pdf-container iframe {
    width:830px;
    height:1000px;
}

button:focus {
  outline: 0;
}
.border-radius-0{
  border-radius: 0!important;
}
.toolbar .tool {
  display: flex;
  color: #2699fb;
  height: 100%;
  padding-top: 0px;
  padding-left: 0px;
  margin-right: 0px;
  align-items: center;
}
.toolbar .tool label {
  font-size: 13px;
  font-family: Arial;
  margin-right: 5px;
}
.toolbar .tool input {
  font-size: 13px;
  color: #2699fb;
  font-weight: 500;
  border: 1px solid #2699fb;
  padding: 5px;
  line-height: 1;
}
.toolbar .tool select{font-size: 13px;
  text-align: center;
  width: 50px;
  color: #2699fb;
  font-weight: 500;
  border: 1px solid #2699fb;
  padding: 5px;
  line-height: 1;}
  .toolbar .tool label {
    margin-bottom: 0;
}
.toolbar .tool label,
.toolbar .tool select,
.toolbar .tool input {
  display: inline-block;
  /* width: auto; */
  /* padding: 0; */
}

.toolbar .tool input {
  width: 50px;
  text-align: center!important;
}

.toolbar .tool .color-tool {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  margin: 4px;
}

.toolbar .tool .color-tool.active {
  position: relative;
}
.toolbar .tool .color-tool.active::after {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(20deg);
  content: "";
  height: 20px;
  width: 20px;
  background: rgb(255 255 255 / 77%);
  border-radius: 20px;
}
.toolbar .tool:nth-last-child(1) {
  float: right;
}
.card {
  padding: 10px;
}
.toolbar .tool .tool-button {
  /* background-color: rgb(50, 54, 57);
  border: 1px solid rgb(50, 54, 57); */
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 50px;
}
.toolbar .tool button.btn-danger i.fa {
  color: #fff;
}

.toolbar .tool .tool-button:hover,
.toolbar .tool .tool-button.active {
  background-color: rgb(0 123 255);
  border-color: rgb(0 123 255);
}
/**********************login -css*****************************/
.tiem-list.active li {
  background-color: transparent;
}
.tiem-list:hover li{
  background-color: transparent;
}
.leftmenu li:has(.active-button) {
  background-color: #1b83db;
}
.MuiCollapse-wrapperInner a.tiem-list.active .MuiButtonBase-root::after {
  content: "";
  background-color: #1b83db;
  position: absolute;
  left: -70px;
  width: 100%;
  height: 36px;
  z-index: 0;
}

.leftmenu li:hover .MuiListItem-root{
  background-color: #1b83db;
}

.MuiCollapse-wrapperInner a.tiem-list.active .MuiButtonBase-root {
  background-color: #1b83db;
  position: relative;
    z-index: 999;
}
.MuiCollapse-wrapperInner a.tiem-list .MuiButtonBase-root .MuiListItemText-root {
  position: relative;
  z-index: 999;
}
.login {
  width: 100%;
  padding: 50px;
  align-items: center;
  justify-content: center;
}
.login-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.right-sidelogin p {
  color: #000;
  font-size: 18px;
  font-family: "Comfortaa";
}
.login-start input {
  border: 1px solid #525659;
  color: #000;
  width: 100%;
}
.right-sidelogin img {
  margin: 0 auto;
  height: 270px;
}
.loginfor {
  width: 100%;
}
.left-sidelogin {
  height: 100%;
  width: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 25px;
}
.right-sidelogin {
  width: 50%;
  background-color: #a4c4b5;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 25px;
}
.loginfor h1 {
  font-size: 50px;
  font-family: "Comfortaa";
  font-weight: 700;
}
.login-start label {
  font-size: 18px;
  padding-left: 5px;
  margin-bottom: 10px;
}

/*************************dashboard css**********************************/
header.bashbordmenu {
  padding: 0.6% 1%;
    box-shadow: none;
    position: fixed;
    /* left: 0; */
    right: 0;
    width: 96%;
    z-index: 99;
    /* height: 100%;  */
}
header.bashbordmenu button.toggler {
  background-color: #2699fb;
  margin-left: 35px;
}
header.bashbordmenu .titletop {
  color: #2699fb;
  /* font-family: 'Arial'; */
  font-weight: 600;
  font-size: 18px !important;
  font-family: 'Montserrat', sans-serif;
}
.user-list {
  background-color: #fff;
  padding: 0px 0px;
}
.aling-item-center {
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.black-text {
  color: #2699fb;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 20px;
  line-height: 1.1rem;
  font-weight: 600;
  font-family: 'Arial';
}

.user-list table th {
  font-size: 15px;
  font-family: 'Arial';
  font-weight: 700;
  background-color: #bce0fd;
  color: #656565;
  border: 1px solid #707070;
}
.backgtable { 
  padding: 0px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.toolbar .tool .tool-button:hover i.fa, .toolbar .tool .tool-button.active i.fa {
  color: #fff;
}
.backgtable th {
  background-color: #fff;
}
.backgtable td {
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #656565;
  font-weight: 600;
  border: none;
  font-family: 'Arial';
}
.backgtable .backtablecolor:nth-last-child(even) {
  background-color: #bce0fd;
}
.backgtable .backtablecolor{
  background-color: #fff;
}


button.btnDelete,
button.btnEdit {
  background-color: transparent !important;
  text-align: center;
  box-shadow: none!important;
  color: #767676;
  outline: none;
}

.full-width .form-control,
.widthd .form-control {
  border: 1px solid #bce0fd;
      width: 100%;
      border-radius: 0;
      outline: none;
}
.form-control::placeholder {
  color: #000;
}
.widthd {
  width: 50%;
  padding: 0px 10px;
}
.form-group {
  margin-bottom: 10px;
}
.form-werp {
  flex-wrap: wrap;
}
.full-width {
  width: 100%;
  padding: 0px 10px;
}

.add-userform {
  /* background-color: #fff; */
  /* padding: 20px 30px; */
  height: 100%;
  /* display: flex; */
  align-items: center;
}
.add-userform h3 {
  margin-left: 0;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 20px;
  line-height: 1.1rem;
  font-weight: 600;
  font-family: 'Arial';
  color: #2699fb;
}
.customclass a.btn {
  background-color: #2699fb;
    font-size: 15px;
    padding: 8px 25px;
    text-align: center;
    font-weight: 500;
    max-width: 150px;
    width: 100%;
    font-family: 'Arial';
    color: #fff;
    border-radius: 2px;
    outline: none;
    border: none;
}

.customclass .btn-primary {
  font-size: 15px;
  padding: 8px 25px;
  text-align: center;
  max-width: 150px;
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  font-family: 'arial';
}

.customclass a.btn.active {
background-color: #1e7e34;
border-color: #1c7430;
}
button#savePpt {
  background-color: #2699fb;
  font-size: 15px;
  padding: 8px 25px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  font-family: 'Arial';
  color: #fff;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.Pptbackandnext button#savePpt {
  margin-right: 10px;
}
.Pptbackandnext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deshboard-meneg { 
  padding-left: 10px!important;
  padding-right: 10px!important;
}
.btnEdit:focus {
  outline: none;
  box-shadow: none;
}
.btnDelete:focus {
  outline: none;
  box-shadow: none;
}
.leftmenu .MuiListItemText-root span.MuiTypography-root {
  font-size: 14px;
  padding-left: 10%;
  margin: 0;
  font-family: 'arial';
    font-weight: 600;
    color: #6a6a6a !important;
}

.tiem-list.active li.MuiListItem-root span.MuiTypography-root,
.tiem-list:hover li.MuiListItem-root span.MuiTypography-root{
  color: #000 !important;
}
.leftmenu li.MuiListItem-root {
  padding-top: 0;
  padding-bottom: 0;
}

.leftmenu li.MuiListItem-root svg.MuiSvgIcon-root {
  font-size: 24px;
}
.text-Red {
  color: red;
  font-size: 15px;
  font-weight: 400;
}
button.btnEdit svg {
  fill: #1d74f5;
  margin: 0 auto;
}
button.btnDelete svg {
  fill: #f20000;
  margin: 0 auto;
}

.kiHXWb {
  height: 100px;
}

.splited_pdf_file_container { 
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
  justify-content: space-evenly;
}
.wrapper-14 .splited_pdf_file_container { 
  flex-direction: column;
}

.splited_ppt_file::-webkit-scrollbar {
  display: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  -ms-overflow-style: hidden;
  scrollbar-width: none;
}

.splited_pdf_file {
  position: relative;
  width: 240px;
  height: 324px;
  cursor: pointer;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.splited_ppt_file {
  position: relative;
  width: auto;
  height: 170px;
  cursor: pointer;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.splited_ppt_file_container {
  display: flex;
}
.splited_ppt_file_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.splited_ppt_file_icons {
  position: relative;
    width: 170px;
    height: 170px;
    cursor: pointer;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
}
.splited_ppt_file_icons img {
  height: inherit !important;
}
.splited_pdf_file::-webkit-scrollbar {
  display: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  -ms-overflow-style: hidden;
  scrollbar-width: none;
}
.customclass .btn.btn-success {
  margin-left: 10px;
}
.innerPdfFile {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: transparent;
  cursor: pointer;
}

.innerPdfFile::-webkit-scrollbar {
  display: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  -ms-overflow-style: hidden;
  scrollbar-width: none;
}

.pdf-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgb(239, 234, 234);
  height: 50px;
  width: 100%;
  z-index: 9999999999;
  display: none;
  transition: 2s;
}
.splited_pdf_file:hover .pdf-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  transition: 2s;
}

.pdf-overlay a {
    margin: 10px;
    width:40px;
    height:40px;
    line-height:40px;
    border-radius: 50px;;
    background-color: #fff;
    color:#000;
    text-align: center;
}

.pdf-overlay a:hover{
    background-color: rgb(244, 51, 51);
    color:#fff;
}



.leftmenu {
  position: fixed!important;
  max-width: 300px;
  width: 100%;
  float: left;
  height: 100%; 
  z-index: 999;
  overflow-y: auto;
  /* top: 102px; */
  padding-top: 20px!important;
  transition: all 0.5s ease 0s!important;
  border-radius: 0 15px 15px 0;
  background-color: #EFF1FD !important;
  box-shadow: 0px 3px 6px #00000029;
}
.leftmenu.closemenu {
  /* transform: translateX(-100%); */
  max-width: 5% !important;
}



.sidbarmain {
  background-color: #1b2429;
  max-width: 95%;
  width: 100%;
  margin-left: auto;
  position: relative;
  float: right;
  padding-top: 105px;
  overflow-y: auto;
  height: 100%;
  padding-left: 20px;
    padding-right: 20px;
    transition: all 0.5s ease 0s;
}
header.bashbordmenu button.toggler.closemenu svg:nth-child(2) {
  display: none;
}

.leftmenu button.toggler.closemenu svg:nth-child(2) {
  display: none;
}
.menu-item-active svg:first-child {
  display: none;
}


.sidbarmain.closemenu {
  max-width: 95%;
  background-color: #1b2429;
}

.leftmenu::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.leftmenu::-webkit-scrollbar-thumb
{	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #F5F5F5;
}
.userleft span.avtar {
  width: 40px;
  /* opacity: 0.8;  */
  /* background-color: #fff6 !important; */
  height: 40px;
  /* background: url(../assets/svg1/profile-icon.svg) !important; */
  border-radius: 50%;
  /* border: 1px solid #fff; */
  /* background-color: #ffffff6e !important; */
  background-size: contain !important;
  background-position: center !important;
}
.userleft span.avtar img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.userleft h4 {
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;
  /* font-family: 'arial'; */
}
.userleft h6 {
  font-size: 10px;
  color: #ffffff;
  font-weight: 400;
  /* font-family: 'arial'; */
}

.content {
  margin-left: 30px;
}
.titletop .dotlive {
  width: 20px;
  height: 20px;
  background-color: #2699fb;
  border-radius: 100px;
  margin-right: 16px;
}
.titletop {
  align-items: center;
}
.rightsidebut {
  display: flex;
  align-items: center;
}
.publishlive {
  font-size: 12px !important;
  color: #ffffff !important;
  background-color: #FF0000 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  width: 150px;
}
.publishedlive {
  font-size: 12px !important;
  color: #ffffff !important;
  background-color: #28a745 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold !important;
  width: 150px;
}


.logoutbtn{
  border-radius: 50%;
  width: 37px;
  color: #fff;
  background-color: #ff0000 !important;
}


.publishlive:hover{
  background-color:#b80101;
}

.dash-logbtn{
    background: red;
    width: 37px;
    border-radius: 50%;
}



.userheding {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdfbackandnext{
  display: flex;
  align-items: center;
  
}
.tool .pdfbackandnext {
  padding-bottom: 10px;
}
.pdfbackandnext button {
  background-color: #2699fb;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  font-family: Arial;
  padding: 8px 25px;
  line-height: 1.8;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.pdfbackandnext button:first-child {
  margin-right: 10px;
}

.custom-scroller {max-height: 100vh;
  overflow-y: auto;
}

/* .custom-scroller > .file_container {margin-right: 20px;} */


/*********************addminpagecss***************/

.widthd input::placeholder{ color: #bce0fd}
.full-width input, .widthd input {
  color: #2699fb!important;
  font-size: 16px;
  font-family: 'arial';
  outline: none;
}
.widthd .form-group,.widthd .form-group {
  margin-bottom: 35px;text-align: center;
}
.widthd .form-group select{
  color: #2699fb!important;
}
.full-width input::placeholder{ color: #bce0fd}

@media only screen and (max-width: 1499px) {

  header.bashbordmenu button.toggler {
    background-color: #2699fb; 
}

.userleft {
  padding: 25px 36px;
}
}



/**********************vodeoEidter-css********************/
.bordernone .MuiDataGrid-columnHeaders{
  border: 1px solid #656565;
  background-color: #bce0fd;
  border-radius: 0;
}
.MuiDataGrid-columnHeaderTitle { 
  font-weight: 700;
  font-family: arial;
  color: #656565;
}
.bordernone .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(even){
  background-color: #bce0fd;

}
.bordernone .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row .MuiDataGrid-cell {
  border: 0;
}

.bordernone .MuiDataGrid-root {
  border: 0;
}
.MuiDataGrid-root .MuiDataGrid-cellContent {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #656565;
  font-weight: 600;
  border: none;
  font-family: 'Arial';
}

.imagecover img {
  max-width: 250px;
  width: 250px;
  max-height: 250px;
}

.bordernone .MuiDataGrid-cellContent {
  overflow: revert!important;
}

a:hover ,button:hover{
  color: #fff!important;
  text-decoration: none;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-color:none!important ;
}
a.rdw-dropdown-selectedtext:hover {
  color: #000!important;
}
.form-control{
  border-color: #bce0fd!important;
  color: #2699fb!important;
}
a {
  text-decoration: none!important;
}
.editbut a, .editbut button {
  border-radius: 0;
  font-size: 12px;
  font-family: Arial;
  min-width: 0 !important;
  padding: 5px !important;
}
.black-text .MuiTypography-root {
  color: #2699fb;
  height: 100%;
  width: 100%;
  text-align: left;
  font-size: 20px;
  line-height: 1.1rem;
  font-weight: 600;
  font-family: 'Arial';
}
.bordernone .MuiGrid-root>.MuiGrid-item .MuiPaper-elevation1.MuiPaper-root .MuiDataGrid-root {
  border: none;
  border-radius: 0;
}


.mmmmmmmm {
  align-items: center;
}

.finishplayer {
  padding: 20px 20px 60px 30px;
}
.newfinalSubmitBtn {
  background-color: #2699fb!important;
  font-size: 13px;
  font-family: Arial;
}
.paddnone li.MuiListItem-root {
  /* padding: 20px 0px; */
}

.vertylegular {
  position: relative;
  padding: 0px 20px;
}

.mainprogrs { 
  padding: 10px 100px;
  width: 100%; 
}

.mainprogrs .multi-step-bar{
  overflow: hidden; 
  margin:15px auto 10px;
  padding: 0 ;
  display: flex;
  justify-content: center;
}
.mainprogrs li {
    text-align: center;
    list-style: none;
    width: 30%;
    float: left;
    position: relative;
    font-weight: 600; 		 
}
.mainprogrs li:before {
  content: "";
  width: 20px;
  display: block;
  height: 20px;
  border: 2px solid #000;
  border-radius: 90%;
  margin: 0 auto 5px auto;
  background-color: #eff1fd;
  z-index: 9;
  position: relative;
}
.mainprogrs li.sucses::before {
  content: "✓";
  position: relative;
  font-size: 12px;
  color: #fff;
  background-color: #248a06;
  border-color: #248a06;
}
  .mainprogrs li:after {
    content: '';
      width: 100%;
      height: 2px;
      background: #000;
      position: absolute;
      left: 50%;
      top: 9px;
      z-index: 1; 
  }
  .mainprogrs li:last-child::after{content: "";
  display: none;}
 
  .mainprogrs li.active:before{
      /* background-color:#2699fb;  */
      border: 2px solid #248A06;
  }

.mainprogrs li p {
  color: #7B7A7F;
  font-size: 14px;
  font-weight: 500;
  /* font-family: Arial; */
}
.flexfileds {
  display: flex;
  width: 100%;
  gap: 5%;
}
.flexfileds .css-12jo7m5 {
  color: #2699fb;
}

.flexfileds .css-qc6sy-singleValue {
  color: #2699fb;
}
.flexfileds .css-14el2xx-placeholder {
  color: #2699fb;

}

.flexfileds label.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root {
  width: 400px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    border-radius: 4px;
}


input {
  border: 1px solid #bce0fd;
  font-size: 16px;
  color: #2699fb;
  font-family: Arial;
  padding: 5px 10px;
  outline: none;
  width:100%;
  font-weight: 400;
}
.formgrop input.noborrder{
border: none!important;
width:auto;
}
.uplodevideoall input.noborrder{
  border: none!important;
  }
  .flexibal {
    display: flex;
}


.formgrop {
  margin-bottom: 20px;
}
textarea::placeholder{
  color: #bce0fd!important;
}
input::placeholder{
  color: #bce0fd!important;
}
textarea {
  resize: vertical;
  border: 1px solid #bce0fd;
  font-size: 14px;
  color: #2699fb;
  font-family: Arial;
  padding: 10px 10px;
  outline: none;
  box-shadow: none;
  width: 100%;
  font-weight: 400;
}
.formgrop select {
  border: 1px solid #bce0fd;
  font-size: 14px;
  color: #2699fb;
  font-family: Arial;
  width: 100%;
  outline: none;
  font-weight: 400;
} 
.formgrop label.actij {
  font-size: 13px;
  color: #2699fb;
  font-weight: 500;
  font-family: Arial;
}
.uplodevideoall video { 
  margin-right: 10px;
  width: 50%;
  max-width: 230px
}
.uplodevideoall{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.uplodevideoall button {
  font-size: 13px;
  font-weight: 500;
  font-family: Arial;
  padding: 8px 25px;
  width: 45%;
  border-radius: 4px;
  background-color: #2699fb;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  height: 40px;
}

.Uploadbtn {
  border: 2px solid #2699fb;
  color: #2699fb;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 13px;
  font-weight: normal;
  margin-right: 12px;
  cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.buttonsave button {
  font-size: 13px;
  font-family: Arial;
  font-weight: 500;
  border-radius: 4px;
  width: 200px;
  padding: 8px 25px;
  background-color: #2699fb;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.buttonsave {
  margin-bottom: 20px;
}
.rightsidebut button#basic-button {
  background-color: #FF0000!important;
    border-radius: 50%;
    min-width: 35px !important;
    min-height: 35px !important;
    padding: 0 !important;
}

.forcontrol{
  margin-right: 10px;
  width: 50%;
}
.flexfileds .forcontrol:last-child{
  margin-right: 0px;
}
.new-header-containt1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%!important;
  flex-basis: auto!important;
  border: 1px solid #2699fb;
}
.mint-sec h5 {
  font-size: 16px;
  color: #2699fb;
  font-weight: 600;
  font-family: Arial;
  margin-bottom: 0;
}
.playiconall {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
}
.icons svg {
  fill: #2699fb;
}
.zoomout button svg {
  color: #2699fb;
}

.zoominout {
  align-items: center;
  display: flex;
}




.inprane input[type="range"] {
  margin: auto; 
  position: relative;
  overflow: hidden;
  height: 5px;
  width: 130px;
  cursor: pointer;
  border-radius: 0;
  border: none;
  -webkit-appearance: none;
  padding: 0;
}

.inprane input[type="range"]::-webkit-slider-runnable-track {
  background: #ddd; 
}
 
.inprane input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background: #fff;
  box-shadow: -200px 0 0 200px dodgerblue; 
  border: 2px solid #999; 
}
.inprane {
  margin-top: -8px;
}
.inprane input[type="range"]::-moz-range-track {
  height: 40px;
  background: dodgerblue;
}

.inprane input[type="range"]::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #999;
  border-radius: 0 !important;
  box-shadow: -200px 0 0 200px dodgerblue;
  box-sizing: border-box;
}

.inprane input[type="range"]::-ms-fill-lower {
  background: dodgerblue;
}

.inprane input[type="range"]::-ms-thumb {
  background: dodgerblue;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

.inprane input[type="range"]::-ms-ticks-after {
  display: none;
}

.inprane input[type="range"]::-ms-ticks-before {
  display: none;
}

.inprane input[type="range"]::-ms-track {
  background: dodgerblue;
  color: transparent;
  height: 40px;
  border: none;
}

.inprane input[type="range"]::-ms-tooltip {
  display: none;
}
.imagesuplod h5 {
  font-size: 13px;
  color: #2699fb;
  font-weight: 500;
  font-family: Arial;
  margin-bottom: 10px;
}
.editbut a.nocolor svg, .editbut button.nocolor svg {
  color: #1976d2;
}
.editbut a.nocolor , .editbut button.nocolor{
  background-color: transparent;
  box-shadow: none;
}
.editbut button.delete svg {
  color: #d32f2f;
}
.kiHXWb{
  border: none!important;
}
.pdfsuplod {
  width: 50%;
}
.pdfsuplod label.sc-bczRLJ.kiHXWb {
  padding: 10px;
  color: white;
  background-color: #1976d2;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}
.pdfsuplod label.sc-bczRLJ.kiHXWb span.sc-hKMtZM.cXBrtO {
  color: #fff;
  font-size: 13px;
  font-family: Arial;
}


.custom-scroller::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #bce0fd;
	background-color: #bce0fd;
}

.custom-scroller::-webkit-scrollbar
{
	width: 5px;
	background-color: #bce0fd;
}

.custom-scroller::-webkit-scrollbar-thumb
{
	background-color: #0062cc;
	border: 2px solid #0062cc;
  border-radius: 10px;
}


select.custom-select{
  border: 1px solid #bce0fd;
    width: 100%;
    border-radius: 0;
    color: #2699fb;
    outline: none;
}
 
h3.dsopForm-heading {
    margin-left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 22.5px;
    /* line-height: 1.1rem; */
    font-weight: 600 !important;
    /* font-family: 'Arial'; */
    color: #000;
    font-family: 'Montserrat', sans-serif !important; 
}
.textoverno p {
  color: #2699fb;
  font-family: 'Arial';
  font-weight: 500;
}
button.btn-dsopForm {
  font-size: 15px;
  padding: 8px 25px;
  border-radius: 4px;
  text-align: center;
  max-width: 300px;
  width: 100%;
  font-family: 'arial';
  background-color: #2699fb;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.buttonateg a {
  padding: 8px 25px;
  background-color: #2699fb;
  font-size: 13px;
  font-family: Arial;
  border-radius: 4px;
  font-weight: 500;
  width: 200px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.buttonateg button {
  margin-right: 20px;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item.active, .tui-image-editor-container .tui-image-editor-help-menu > .tui-image-editor-item.active {
  background-color: #fff!important; 
}
.tui-image-editor-container .tui-image-editor-controls {
  /* background-color: #ffffff!important; */
  border: 1px solid #000;
}
.tui-image-editor-submenu-item .tui-image-editor-button.preset.preset-none.active label {
  color: #2699fb;
}
.tui-image-editor-container .tui-image-editor-icpartition {
  background-color: #2699fb!important;
}
.tui-image-editor-container .tui-image-editor-help-menu { 
  background-color: rgb(255 255 255)!important; 
}
.tui-image-editor-item svg.svg_ic-menu use.normal {
  fill: #fff;
}

.tui-image-editor-menu-crop ul li .active label {
  color: #2699fb!important;
}
a.btn.btn-link.mt-3 {
  font-size: 13px;
  font-family: Arial;
  font-weight: 500;
  margin-top: 20px;
  display: block;
  max-width: 100px;
  text-align: center;
}
.MuiDataGrid-root .MuiDataGrid-iconSeparator { 
  opacity: 0;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader {
  border: 1px solid #656565;
}

    .toppadding {
      padding-top: 6%!important;
  }
  body> svg {
    display: none!important;
}
.flexbox .formgrop img,.flexbox .uplodevideoall img{
  width: 60px;
}
.flexbox {
  display: flex;
}
.flexbox .formgrop {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 2px;
}


@media only screen and (max-width: 1250px) {
  .toolbar .tool .color-tool {
    height: 20px;
    width: 20px; 
}
.toolbar .tool .tool-button { 
  font-size: 13px;
  padding: 5px 8px; 
}
}

.leftmenu.closemenu .sidebartoggle{
  width: 10% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
}

.sidebartoggle{
  width: 10%;
  display: flex;
  margin-left: -85% !important;
  margin-right: 0 !important;
  padding: 10px !important;
}

.sidebartoggle:hover{
box-shadow: none;
background-color:transparent !important;
color: #000 !important;
}

.sidebarmenu{
width: 85%;
margin: 0 auto;
display: flex;
gap: 10px ;
align-items: center;
justify-content: center;
flex-direction: column;
}

.leftmenu.closemenu .sidebarmenu{
  align-items: center;
}

.userleft {
  background-image: url(../assets/svg1/profile-sec-bg.svg) !important;
  background-position: center;
  color: #fff;
  border-radius: 15px;
  width: 100%;
  /* padding: 10px 0px !important; */
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 0;
}

.userleft.closed{
  background-image: url(../assets/svg1/sidebarprofile.svg) !important;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 0px;
  cursor: pointer;
}


.userleft.closed img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.leftmenu.closemenu .userleft{
  display: none;
}

.leftmenu .userleft.closed{
  display: none;
}

.leftmenu.closemenu .userleft.closed{
  display: block;
}
.content{
  margin-left: 0 !important;
}

.myprofileicon{
  border-radius: 50%;
  font-size: 10px;
  background: #fff3;
  backdrop-filter: blur(50px);
  border: 0.5px solid #FFFFFF;
  cursor: pointer;
}
.myprofileicon svg{
  width: 22px;
  padding: 4px;
  height: 22px;
}
.userleftone{
  display: flex;
  gap: 10px;
}
.userlefttwo{
  margin-top: 16%;
}

.leftmenu.closemenu .sidenavtitle{
  display: none !important;
}

.leftmenu li.MuiListItem-root svg.MuiSvgIcon-root{
  color: #6a6a6a !important;
}

.leftmenu.closemenu .tiem-list li::after{
  /* content: ""; */
  clip-path: polygon(75% 26%, 100% 0, 100% 100%, 75% 74%);
  background-color: #1b2429;
  height: 150%;
  width: 45px;
  right: -13%;
  position: absolute;
  /* top: 0; */
}

.leftmenu.closemenu .tiem-list li::before{
  /* content: ""; */
  clip-path: polygon(0 0, 25% 26%, 25% 74%, 0 100%);
  background-color: #1b2429;
  height: 150%;
  width: 45px;
  left: -13%;
  position: absolute;
  /* top: 0; */
}

.tiem-list li::after{
  /* content: ""; */
  clip-path: polygon(75% 26%, 100% 0, 100% 100%, 75% 74%);
  background-color: #1b2429;
  height: 150%;
  width: 45px;
  right: -9%;
  position: absolute;
  /* top: 0; */
}

.tiem-list li::before{
  /* content: ""; */
  clip-path: polygon(0 0, 25% 26%, 25% 74%, 0 100%);
  background-color: #1b2429;
  height: 150%;
  width: 45px;
  left: -9%;
  position: absolute;
  /* top: 0; */
}


.tiem-list.active li::after,
.tiem-list.active li::before,
.tiem-list:hover li::after,
.tiem-list:hover li::before{
  content: "";
} 

.tiem-list:hover{
background-color: transparent;
box-shadow: none;
}

.tiem-list.active li.MuiListItem-root svg.MuiSvgIcon-root{
  color: #1b2429 !important;
}


.tiem-list li .css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover{
  background-color: transparent !important;
}

.tiem-list{
  width: 100%;
}

.leftmenu.closemenu .tiem-list{
  width: auto;
}

.tiem-list:hover li.MuiListItem-root svg.MuiSvgIcon-root{
color: #1b2429 !important;
}

.rightsidebar{
  width: 100%;
  height: auto;
  background-color: #EFF1FD !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 15px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.rightsidebar::-webkit-scrollbar { 
  display: none !important;  /* Safari and Chrome */
}

.sidbarmain{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.sidbarmain::-webkit-scrollbar { 
  display: none !important;  /* Safari and Chrome */
}

.leftmenu{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.leftmenu::-webkit-scrollbar { 
  display: none !important;  /* Safari and Chrome */
}


.leftmenu .MuiListItemText-root span.MuiTypography-root{
  font-family: 'Montserrat', sans-serif;
}

.form-werp{
  width: 70%;
  margin: 0 auto;
}

#upload-button{
  color: #b7b9c2 !important;
  border:  1px solid #b7b9c2 !important;
  padding: 14px !important;
  border-radius: 10px !important;
}

.uploadstyle{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  font-family: 'Montserrat', sans-serif !important;
  /* margin-left: -9%; */
}

.uploadstyle p{
  margin-bottom: 0;
  color: #0587FE;
  font-size: 15px;
}


.uploadstyle p:last-child{
  margin-bottom: 0;
  font-size: 11px;
  color: #707070;
}

.uploadstyle p span{
  color: #707070;
  font-size: 11px;
  padding-left: 5px;
}

.addeditorform{
  padding: 0;
  margin: 0 !important;
}

.ditgitalform{
  width: 70%;
  margin: 0 auto;
}
.formgrop.imagecover{
  display: flex;
  justify-content: space-between;
}

.buttonsave.pdfsavebtn{
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    margin-top: 50px;
}

.pdfedittitle{
  font-family: 'Montserrat', sans-serif !important;
  color: #000 !important;
  font-weight: 600;
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
}

.customclass.pdflist a.btn{
  background: #000;
  border-radius: 5px;
  padding: 8px 0;
  font-family: 'Montserrat', sans-serif !important;
  width: 28%;
  font-size: 13px;
}
.react-pdf__Page__canvas{
  display: block;
    user-select: none;
    width: -webkit-fill-available !important;
    object-position: bottom !important;
    object-fit: contain !important;
    height: fit-content !important;
}

.splited_pdf_file.m-2{
  width: 300px;
  height: 200px;
  border: 1px solid #7B7A7F;
  border-radius: 17px;
}

.grid.backgtable.pdfformedit{
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  padding-top: 30px;
  margin: 0 auto;
  width: 80%;
}

.logoutno#savePDF{
  width: 12%;
}

.pdfsavebtn{
  display: flex;
  justify-content: center;
  gap: 3%;
}

.pdfbackandnext.pdfsavebtn{
  padding: 50px 0 40px 0;
}
/* .splited_pdf_file::after{
  content: '';
  height: 50px;
  width: 50px;
  z-index: 9;
  top: 70%;
  right: -10%;
  position: absolute;
  display: block;
  background-color: red;
} */
.pdficonsym {
  color: black;
  position: absolute;
  top: 70%;
  background-repeat: no-repeat;
  right: -2%;
  width: 65px;
  background-size: contain;
  height: 50px;
  background-image: url(../assets/svg1/pdficonp.png) !important;;
}

.flexfileds .css-14el2xx-placeholder {
  color: #616166 !important;
  font-size: 14px;
}

.logoutno.imgedit{
  min-width: 150px !important;
  font-size: 16px !important;
}

.repoternon{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonsave.addvidbtn,.uplodevideoall{
  margin-bottom:0px ;
}
.buttonsave.addvidbtn button{

width: auto !important;
padding: 15px !important;
min-width: 0 !important;
line-height: 10px;
font-size: 30px;
background-color: #1b2429;
}