@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");

@layer components {
    .human-logo {
        @apply h-10 w-10  rounded-lg;
    }
    .TL {
    }
    .btn {
        @apply py-2 px-4 bg-secondary1 font-comfortaa text-font1 rounded-lg shadow-md;
    }
    .btn:hover {
        @apply bg-secondary2;
    }
    .btn:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }
    /*header start*/
    .header {
        @apply h-20 w-full items-center justify-between bg-primary1 text-font1  font-comfortaa;
    }
    .hamburger-header {
        @apply items-center h-12 w-6 ml-4 cursor-pointer;
    }
    .header-digital {
        @apply font-bold text-2xl text-center p-3;
    }

    .header-right {
        @apply items-center text-center p-5;
    }
    /*header End*/
    /* sidebar start */

    .main-sidebar {
        @apply w-1/6 h-screen bg-primary1 text-font1 font-bold overflow-invisible;
    }

    .sidebar-logo {
        @apply text-center italic w-14 ml-1 mt-2;
    }

    .sidebar-userName {
        @apply text-center w-64 mt-4 font-comfortaa font-bold cursor-default;
    }

    .sidebar-human {
        @apply h-12 w-36 rounded-full;
    }

    .sidebar-menuItem {
        @apply p-4 text-left font-comfortaa;
    }

    .sidebar-menuItem:hover {
        @apply bg-secondary1;
    }
    .sidebar-menuItem-icon {
        @apply items-center text-2xl;
    }
    .sidebar-subMenu {
        @apply p-3 font-comfortaa;
    }

    .sidebar-subMenu:hover {
        @apply hover:bg-secondary1;
    }
    .sidebar-h1 {
        @apply pl-4;
    }

    /* sidebar End */

    /* Digital-Sop VideoEditor Start */

    .btnView {
        @apply py-2 px-6 w-full bg-green font-comfortaa font-semibold text-font1 rounded-md shadow-md;
    }
    .btnView:hover {
        @apply bg-darkGreen;
    }
    .btnView:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }

    .btnEdit {
        @apply py-2 px-6 w-full bg-blue font-comfortaa font-semibold text-font1 rounded-md shadow-md;
    }
    .btnEdit:hover {
        @apply bg-darkBlue;
    }
    .btnEdit:focus {
        @apply outline-none ring-2 ring-darkBlue ring-opacity-75;
    }

    .btnDelete {
        @apply py-2 px-6 w-full bg-red font-comfortaa font-semibold text-font1 rounded-md shadow-md;
    }
    .btnDelete:hover {
        @apply bg-darkRed;
    }
    .btnDelete:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }

    /* Digital-Sop VideoEditor End */

    /* Didgital-SOP FORM  Start*/

    /* //Processline Start */
    .processLine-ol {
        @apply mx-52 pt-2 items-center;
    }
    .processLine-li {
        @apply mb-5 sm:mb-0 w-full font-comfortaa;
    }
    .processLine-outer-div1 {
        @apply items-center ml-2;
    }
    .processLine-inner-circle {
        @apply z-10 justify-center items-center w-6 h-6 bg-font2 rounded-full ring-0 ring-white  sm:ring-2 shrink-0;
    }
    .processLine-i-circle-svg {
        @apply w-6 h-3  dark:text-secondary2;
    }
    .processLine-inner-line {
        @apply hidden  w-full bg-input1 h-0.5;
    }
    .processLine-outer-div2 {
        @apply mt-3 sm:pr-8;
    }
    .processLine-o-div-h3 {
        @apply text-sm font-semibold  dark:text-white;
    }

    /* //Processline End */

    .dsopForm-heading {
        @apply w-full h-full font-bold text-left text-xl font-comfortaa;
    }

    /* //next button */
    .btn-dsopForm {
        @apply py-3 ml-7 mt-4 w-full rounded-lg font-comfortaa bg-secondary1 text-font1  font-bold shadow-md;
    }
    .btn-dsopForm:hover {
        @apply bg-secondary2;
    }
    .btn-dsopForm:focus {
        @apply outline-none ring-2 ring-secondary1 ring-opacity-75;
    }

    /* Didgital-SOP FORM  End*/

    /* VideoPlayer Start */

    .videoPlayer {
        @apply w-full h-full bg-primary2 text-font1 font-comfortaa;
    }

    /* //Video Edit Tools Start */
    .btn-VET-AT {
        @apply p-0.5 rounded-lg w-full bg-input1 text-font1  font-bold shadow-md font-comfortaa;
    }
    .btn-VET-AT:hover {
        @apply bg-secondary2;
    }
    .btn-VET-AT:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }

    .btn-VET-upload {
        @apply p-0.5 mb-2 rounded-xl w-full  bg-secondary1 text-font1  font-bold shadow-md;
    }
    .btn-VET-upload:hover {
        @apply bg-secondary2;
    }
    .btn-VET-upload:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }
    .btn-VET-addQuiz {
        @apply p-2 w-full  rounded-xl bg-secondary1 text-font1 font-bold shadow-md;
    }
    .btn-VET-addQuiz:hover {
        @apply bg-secondary2;
    }
    .btn-VET-addQuiz:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }

    /* //Video Edit Tools End */

    /* Video Render Start */
    .control {
        @apply mx-3 p-1 w-full justify-between items-center text-font1;
    }

    .btn-control {
        @apply justify-around w-64 items-center text-4xl;
    }

    /* Video Render End */

    /* //Layers Start */
    .vp-Layers {
        @apply h-full w-full text-center items-center;
    }
    .btn-vp-Layers {
        @apply bg-font2 m-1 p-1 border-double border-2  border-secondary2;
    }
    .zoom {
        @apply w-full justify-between items-center text-3xl;
    }
    .zoom-slider {
        @apply w-80 mb-3 relative;
    }
    .zoom-input {
        @apply appearance-none w-4/5 h-1 p-0;
    }
    .zoom-input:hover {
        @apply bg-font2;
    }
    .zoom-input:focus {
        @apply outline-none ring-0 shadow-none;
    }
    /* //Layers End */

    /* //Timeline-Heading Start */
    .th-timestamp {
        @apply w-1/5 h-10 justify-between bg-black;
    }

    .th-timeline {
        @apply w-4/5 ml-2 h-0.5 text-sm font-black text-font2 text-left;
    }
    .th-NewTimeline {
        @apply w-4/5 ml-2 h-0.5 text-sm font-black text-font2 text-left;
    }
    /* //Timeline-Heading End */

    /* //Timestamp Start */
    .ts-track {
        @apply bg-white text-black text-center;
    }
    .ts-trackElements {
        @apply h-10 border;
    }
    /* //Timestamp End*/

    /* //TimeLine Start */

    /* //TimeLine End */

    /* VideoPlayer End */

    /* Courses Start */

    /* Add QuestionPage start */
    .btnEdit-courseQ {
        @apply px-4 p-1  w-14  bg-blue font-comfortaa font-semibold text-font1 text-2xl rounded-md shadow-md;
    }
    .btnEdit-courseQ:hover {
        @apply bg-darkBlue;
    }
    .btnEdit-courseQ:focus {
        @apply outline-none ring-2 ring-darkBlue ring-opacity-75;
    }

    .btnDelete-courseQ {
        @apply p-1 px-4 w-14 bg-red font-comfortaa font-semibold text-font1 text-2xl rounded-md shadow-md;
    }
    .btnDelete-courseQ:hover {
        @apply bg-darkRed;
    }
    .btnDelete-courseQ:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }
    /* Add QuestionPage End */

    /* Add Answer start   */
    .btnEdit-courseA {
        @apply px-4 p-1  w-14   font-comfortaa font-semibold text-black text-2xl rounded-md shadow-md;
    }
    .btnEdit-courseA:hover {
        @apply bg-darkBlue text-white;
    }
    .btnEdit-courseA:focus {
        @apply outline-none ring-2 ring-darkBlue ring-opacity-75;
    }

    .btnDelete-courseA {
        @apply p-1 px-4 w-14 bg-white font-comfortaa font-semibold text-black text-2xl rounded-md shadow-md;
    }
    .btnDelete-courseA:hover {
        @apply bg-darkRed text-white;
    }
    .btnDelete-courseA:focus {
        @apply outline-none ring-2 ring-secondary2 ring-opacity-75;
    }

    /* Add Answer end */
    /* Courses End */
}

#ppt-container {
    border: 1px solid black;
    display: flex;
  }
#edit_ppt_canvas {
    position: absolute !important;
  }
  

/* font family */
.font-comfortaa {
    font-family: Comfortaa;
}

/* Slider start */

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #25fc50a0;
    background: #ffffff00;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #b7ff00;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04aa6d;
    /* background: #1aec07; */
    cursor: pointer;
}

/* Slider end */

/* scrollbar Hiding start */

.overflow-invisible {
    overflow-y: scroll;
}

.overflow-invisible::-webkit-scrollbar {
    display: none;
}

/* scrollbar Hiding end */

/* table start  */
th {
    background-color: #b3b3b3;
    border-bottom: 1px solid rgb(34, 196, 255);
    border-left: 0.5px solid rgb(34, 196, 255);
    border-right: 0.5px solid rgb(34, 196, 255);
}

td {
    border-collapse: collapse;
    border-left: 0.5px solid rgb(136, 223, 255);
    border-right: 0.5px solid rgb(182, 232, 250);
    border-bottom: 1px solid rgb(34, 196, 255);
}

.white_text {
    color: #fff !important;
}
/* .dsopForm-heading {
    padding: 5px 75px 5px;
} */
.kiHXWb{
    height: 100px;
    width: 500px;
}
/* table stop  */

.mr-15 {
    margin-right: 15px !important;
}

.tui-image-editor-menu-text {
    background: gray;
}
.tui-image-editor-menu-draw {
    background: gray;
}
