@font-face {
    font-family: 'HelveticaNeueLTW06-97BlkCnObl';
    src: url('HelveticaNeueLTW06-97BlkCnObl.woff2') format('woff2'),
        url('HelveticaNeueLTW06-97BlkCnObl.woff') format('woff'),
        url('HelveticaNeueLTW06-97BlkCnObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-MediumExt.woff2') format('woff2'),
        url('HelveticaNeue-MediumExt.woff') format('woff'),
        url('HelveticaNeue-MediumExt.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('HelveticaNeue-UltraLight.woff') format('woff'),
        url('HelveticaNeue-UltraLight.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-66MediumIt';
    src: url('HelveticaNeueW01-66MediumIt.woff2') format('woff2'),
        url('HelveticaNeueW01-66MediumIt.woff') format('woff'),
        url('HelveticaNeueW01-66MediumIt.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-77BdCnObl';
    src: url('HelveticaNeueW01-77BdCnObl.woff2') format('woff2'),
        url('HelveticaNeueW01-77BdCnObl.woff') format('woff'),
        url('HelveticaNeueW01-77BdCnObl.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-87HvCnObl';
    src: url('HelveticaNeueW01-87HvCnObl.woff2') format('woff2'),
        url('HelveticaNeueW01-87HvCnObl.woff') format('woff'),
        url('HelveticaNeueW01-87HvCnObl.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLTW06-93BlkExtObl';
    src: url('HelveticaNeueLTW06-93BlkExtObl.woff2') format('woff2'),
        url('HelveticaNeueLTW06-93BlkExtObl.woff') format('woff'),
        url('HelveticaNeueLTW06-93BlkExtObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

