.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;   
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.kiHXWb{
  height: 100px;
  width: 500px;
}

.css-nzerlt {
  height: auto !important;
  overflow: auto !important;
}

.finalSubmitBtn {
  margin-top: 20px !important;
  float: right;
  margin-right: 10px !important;
}

.newfinalSubmitBtn {
  /* margin-top: 20px !important; */
  float: right;
  background-color: #659EC7!important;
  /* margin-right: 10px !important; */
}
.NextButton{
  width: -webkit-fill-available;
  background-color: #659EC7!important;

}
.btnAddQuix{
  padding: 10px 30px;
  border-radius: 0px;
  float: left;
  color: white;
  background-color: #2699fb!important;
  font-family: 'Arial';
  font-size: 14px;
  font-weight: bold;

}
.css-eldoax {
  margin-right: 0;
}

.css-3ynorm-MuiListItem-root {
  height: 50px !important;
}
.css-1oa8q1b-MuiTypography-root {
  font-family : 'Abyssinica SIL', serif !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99999999;
}

/* .ReactModal__Content.ReactModal__Content--after-open {
  z-index: 1000;
  height: 70%;
  width: 70%;
  left: 15% !important;
} */
.ReactModal__Content.ReactModal__Content--after-open {
  z-index: 1000;
  height: auto;
  width: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin: 0 auto;
    min-height: 1px;
    max-height: fit-content;
}

.MuiBox-root.css-1rg60ey {
  height: 460px;
  position: relative;
  overflow: hidden;
}



/* div.timeline-editor-action:has(> div.cropbox) {

} */
 

div.timeline-editor-action:has(.cropbox) {
  background: #ff000059 !important;   
}
.timeline-editor-action {
  background-color: #ff000059 !important;
}



li.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.css-qecj0d-MuiListItem-root {
  height: 46px;
  margin-bottom: 2px;
}
/* .wrapper-14 {display: flex;} */
/* 

.wrapper-14 .font-comfortaa.user-list {max-width: 220px;margin-top: 60px;padding-top: 10px;padding-bottom: 10px;} */

.wrapper-14 > .grid.backgtable{
  margin-top: 60px;padding-bottom: 10px;
}

.mt-60px{
  margin-top: 60px;

}


.timeline-editor.timeline-editor-disable .timeline-editor-time-area {
  background: #769ebf;
}
.timeline-editor-edit-row {
  background-image: none !important;
  margin-top: 2px !important;
}
.timeline-editor{
  background-color: #cce3fc!important;
}
.btnRemove{
  background-color: red !important;
}
.dropdownRemoveNew{
  display: none !important;
}
.cardLayout{
  padding-top: 15px;
  margin-bottom: 15px;
}


.css-1vlyx1h-MuiGrid-root {  
  height: 450px !important;
}


.play-control {
  visibility: hidden;
}
.userlist{
display: block !important;
margin-right: 10px;
}
.locationpdf{
  display: block !important;
  }
