

/********************dashbordcss start***************************/

/* .deshboard-meneg{
height: 100vh;
} */
.dashbordcadrmain {
  display: flex;
  align-items: center;
  padding-bottom: 2%;
  justify-content: space-evenly;
}
.onecard {
  /* border-radius: 4px; */
  /* padding: 15px 12px 15px 15px; */
  /* background-color: #fff; */
  /* box-shadow: 0px 6px 28px -5px rgb(196 196 196); */
  /* margin: 8px 8px; */
 width: 16%;
 border: 1px solid var(--unnamed-color-ffffff);
background: #f0eff1 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000004D;
border: 1px solid #FFFFFF;
border-radius: 11px;
opacity: 1;
padding: 0px 5px 0px 5px;
backdrop-filter: blur(50px);
}
.onecard .card {
  border: none;
  background: none;
  padding: 5px 10px;
}
.card h5.card-title {
  font-size: 14px;
  color: #767676;
  font-family: 'helvetica neue';
  font-weight: 500; 
}

.apexcharts-text tspan{
  font-weight: 500 !important;
  font-family: 'Montserrat', sans-serif !important; 
}

.onecard .card h5.card-title{
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  letter-spacing: -0.5px;
  font-family: 'Montserrat', sans-serif;
}
.card h3.card-text {
  font-size: 26px;
  color: #676767;
  font-family: 'helvetica neue';
  font-weight: 500; 
  margin: 10px 0px;
}

.onecard .card h3.card-text{
  font-size: 20px;
  margin: 0;
  color: #000;
  font-family: 'Montserrat', sans-serif;

}
.card h4 {
  font-size: 14px;
  font-family: 'helvetica neue';
  font-weight: 500;
}

.onecard  .card h4{
  margin: 0;
}

.card h4.text-success {
  color: #00a34f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card h4.text-danger{
  color: #e30000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card p {
  font-size: 11px;
  color: #808080;
  font-family: 'helvetica neue';
}
.leftmenu li .MuiListItemIcon-root.white_text {
  min-width: 30px!important;
}
.MuiButtonBase-root.MuiListItemButton-root {
  padding-left: 0!important;
  padding-right: 0!important;
}
/* .MuiPaper-rounded.MuiPaper-elevation1 {
  top: 106px!important;
} */



/* .userleft {
  background-color: #bce0fd;
  padding: 0 0;
  display: flex;
  align-items: center;
} */

/* .userleft span.avtar {
  width: 40px;
  height: 40px;
  background-color: #2699fb;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 20px;
  color: #fff;
} */
/* .userleft h4 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  font-family: 'arial';
}
.userleft h6 {
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  font-family: 'arial';
} */

.content {
  margin-left: 30px;
}
.titletop .dotlive {
  width: 20px;
  height: 20px;
  background-color: #2699fb;
  border-radius: 100px;
  margin-right: 16px;
}
.titletop {
  align-items: center;
}
.desbordcard {
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
}



.userheding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.mainflex {
  display: flex;
  align-items: center;
  width: 98%;
  margin: 10px auto;
  justify-content: space-around;
  margin-bottom: 14px;
  position: relative;
}
.chart {
  width: 55%;
  min-height: 300px;
  background-color: #ffffff;
  box-shadow: 0px -1px 5px -2px;
  border: 1px solid var(--unnamed-color-ffffff);
  background: #FAF9FE 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  opacity: 1;
  backdrop-filter: blur(50px);
}
.representation {
  width: 49%;
  min-height: 230px;
  background-color: #f1f9ff;
  display: flex;
    align-items: center;
    justify-content: center;
}
.alluser ul li {
  /* margin-right: 30px; */
}
.alluser ul {
  display: flex;
  padding: 0;
  align-items: center; 
}
.alluser ul li .user span.userphoto {
  width: 50px;
  height: 50px;
  /* border: 1px solid #707070; */
  color: #1c1c1c;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--unnamed-color-ffffff);
  background: transparent ;
  box-shadow: 0px 3px 6px #0000004D;
  border: 1px solid #FFFFFF;
}

.alluser ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.alluser ul li .user {
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.alluser {
  height: 300px;
  gap: 10%;
  display: flex;
  flex-direction: column;
    /* justify-content: space-around; */
  padding: 20px 0;
  width: 40%;
  /* margin: 0px 8px; */
  background-color: transparent;
  /* box-shadow: -1px 2px 25px -17px ; */
  /* padding: 20px 25px 25px 25px; */
  position: relative;
  z-index: 9;
  border-radius: 2px;
}

.charttitle {
  display: flex;
  padding: 20px 20px;
  justify-content: space-between;
  color: #7B7A7F !important;
}

.charttitle p{
  margin: 0 !important;
  font-weight: 500;
}
.userheding a.viewall:hover {
  color: #268bdb!important;
  box-shadow: none;
}
.userheding h5 {
  font-size: 15px;
  color: #7B7A7F;
  font-weight: 500;
  /* font-family: 'helvetica neue'; */
}
.userheding a.viewall {
  font-size: 12px;
  color: #268bdb;
  font-weight: bold;
  font-family: 'helvetica neue';
}
.alluser ul li .user p {
  font-size: 14px;
    color: #000;
    /* font-family: 'helvetica neue'; */
    line-height: 2;
    /* margin-top: 10px; */
    font-weight: 500;
    margin-bottom: 0px;
}
.alluser ul li .user span {
  font-size: 12px;
  color: #000;
  line-height: 1;
  height: auto;
  font-weight: 500;
  /* font-family: 'helvetica neue'; */
  display: block;
}

.representation h5 {
  font-size: 16px;
  color: #2699fb;
  font-family: 'arial';
  font-weight: bold;
}
#edit_video video {
    width: auto !important;
    height: auto !important;
}
#video_edit .canvas-container {
  position: absolute !important;
}
#ppt-container .canvas-container {
  position: absolute !important;
}


.handleimg {
  background: #E6E8F6 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 22px;
  HEIGHT: 15PX;
  width: 90px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.back_arrow_style {
  color: #000000;
  font-size: 24px !important;
  border: 1px solid black;
  padding: 8px;
  border-radius: 40px;
  position: absolute;
}

.dashcarddetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user .userphoto img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#overlay{
display: none;
}

.activeoverlay{
  /* height: 100vh;
  width: 100vw;
  z-index: 99;
  position: absolute;
  opacity: 0.15;
  background-color: #000;
  display: block !important; */
}


.flex.contacthead {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.flex.contacthead img{
  width: 80px;
}

.contactmain{
  padding:20px 10px ;
}

.contactformmain{
  width: 80%;
  margin: 0 auto;
}

.contactinputform{
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}

.inputformwidth{
  width: 40%;
  display: flex;
  justify-content: center;
}

.inputformwidth1{
  width: 95%;
}
.inputformwidthuser{
  width: 90%
}

.inputformwidth2{
  width: 95%;
}
.inputformwidth3{
  width: 90%;
}

.contactformflex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.btn-dsopForm{
  margin-left: 0 !important;
  padding: 10px 20px !important;
  background-color: #1b2429 !important;
  font-family: 'Montserrat', sans-serif !important;
  max-width: 30% !important;
}

.btn-dsopForm:hover{
  background-color: #0c2d3f !important;
}

.textoverno p {
  color: #1b2429;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 0;
}

.logoutdash{
  height: 85vh;
}
.logoutdashfirst {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} 

.logoutno {
  padding: 15px 15px !important;
  background: #E3E3E3 !important;
  min-width: 110px !important;
  color: #000 !important;
  text-align: center !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  cursor: pointer !important;
  font-family: 'Montserrat', sans-serif !important;
  border: none !important;

}

.logoutno:hover,.logoutyes:hover{
  background-color: #1b2429 !important;
  color: white !important;
}

.logoutyes {
  padding: 10px 0px;
  cursor: pointer;
  background: #E3E3E3;
  min-width: 110px;
  color: #000;
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
}
.logoutdashsecond {
  display: flex;
  justify-content: center;
  gap: 35px;
}
.logouthead p{
  color: #818385;
  font-size: 18px;
  font-weight: 500;
}
.logouthead h3{
  font-family: 'Montserrat', sans-serif !important; 
  font-weight: 700;
}


@keyframes hello {
  0% {
    -webkit-transform: rotateY(0deg);
  }
  50%{
    -webkit-transform: rotateY(180deg);
  }
  100%{
    -webkit-transform: rotateY(0deg);
  }
}



#basic-button.classname .abcd svg{
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  animation-name: hello;
  animation-duration: 1s;
  animation-iteration-count: 1;
}


.menuitemusers {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.menuitemusers img{
  width: 25px;
  margin-right: 10%;
}

.dropdown-toggle.userlistmenu::after {
  content: none !important;
}

#dropdown-basic.userlistmenu{
background: none;
border: none;
box-shadow: none;
color: #000;
padding: 0 !important;
}

#dropdown-basic.userlistmenu:hover{
  color: #000 !important;
}

#dropdown-basic.userlistmenu svg{
  font-size: 25px !important;
}

.dropdown-toggle.userlistmenu .dropdown-item:focus .menuitemusers, .dropdown-item:hover .menuitemusers{
  color: #000 !important;
}

.userlistmenu.dropdown-toggle a.dropdown-item{
  padding: 5px 10px !important; 
}

.userlistmenu.dropdown-toggle .dropdown-menu{
  min-width: 100px !important;
}

.userstab{
  /* padding: 5% 0 0 0; */
  background-color: #fff;
  /* border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 3px 6px #00000029; */
}

.usermainaddbtn{
  display: flex;
  justify-content: end;
}

.usermainaddbtn a.btn {
  background: #1b2429;
  margin: 2% 2% 0 0;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  max-width: 121px;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.userlistmain{
  background-color: #fff !important;
}

.userstab .nav-item {
  width: 20%;
}

.contactus .userstab .nav-item {
  width: 23%;
}

.userstab .nav-item button{
  width: 100%;
}

.userstab .nav-item button.nav-link{
  border: none;
  color: #000;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: uppercase;
  font-size: 14px;
}



.userstab .nav-item button.nav-link.active{
  border: none;
  border-bottom: 3px solid red;
  margin: 0;
  color: #000 !important;
  box-shadow: none !important;

}

.userstab .nav-item button.nav-link:hover{
  color: #000 !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 3px solid red;
  margin: 0;
}


.userstab .nav-item button.nav-link span {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  padding: 15px 0;
}

.userstab .nav-item button.nav-link span svg{
  font-size: 25px;
}


.userstab .nav-tabs{
  margin-left: 10px;
}

.user-list table th{
  background-color: transparent;
  border: none;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;

}

.backgtable .backtablecolor:nth-last-child(even){
  background-color: transparent;
}

.backgtable td{
  margin: 0;
}
.backtablecolor table tr{
border-bottom : 1px solid #d9d9db;
}

.backgtable td{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  color: #000;

}

.backgtable .backtablecolor {
  background-color: inherit;
}

.backgtable{
  background-color:#EFF1FD;
  border-radius: 0;
  box-shadow: 0px 3px 6px #00000029;
}

.Editorcustom{
  padding: 0 !important;
}
.Editorgridcustom{
  margin: 0 !important;
}
.bordernone .MuiDataGrid-columnHeaders{
  border: 0 !important;
  background-color: transparent;
  min-width: 100% !important; 
}

.bordernone{
  height: 450px;
}

.titileditor{
  
  text-align: center;
  margin: 0 auto;
  text-align: center;
  font-size: 22.5px;
  /* line-height: 1.1rem; */
  font-weight: 600 !important;
  /* font-family: 'Arial'; */
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
}

.locationpdf .css-1s2u09g-control{
  background-color: transparent;
  border-color: #b5b6c0;
}

.flexfileds .css-14el2xx-placeholder{
  color: #000;
}

.pdfeditform #upload-button{
  width: 80px !important;
  height: 80px !important;
}

.pdfupone,.pdfuptwo {
  width: 47%;
  align-items: center;
  /* justify-content: center; */
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedError.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.removevid.css-yup6gp-MuiButtonBase-root-MuiButton-root {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0;
  min-width: 10px;
  line-height: 0 !important;
  justify-content: center;
  border-radius: 20px;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
}

/* .uplodevideoall{
position: absolute;
top: 0;
width: 80px;
background-color: aqua;
height: 80px;
border-radius: 10px;
background: transparent;
} */

/* .neweditorstyle .css-gl260s-MuiDataGrid-columnHeadersInner{
  width: 100%;
}

.neweditorstyle .css-yrdy0g-MuiDataGrid-columnHeaderRow{
  width: 100%;
}

.neweditorstyle .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable{
min-width: 30% !important;
}

.neweditorstyle .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader{
  min-width: 70% !important;
} */

.editpdfmain{
  background-color: transparent !important;
  width: 95%;
  margin: 0 auto;
}
.toolbarmain{
border: 1px solid var(--unnamed-color-ffffff);
background: #ffffffa3 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #0000004D;
border: 1px solid #FFFFFF;
border-radius: 13px;
opacity: 1;
backdrop-filter: blur(50px);
}

.foroverflow{
  overflow: hidden;
}

.toolbaronright{
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-left: 6%;
  padding: 10px;
  margin-top: 5%;
  border: 1px solid var(--unnamed-color-ffffff);
  background: #ffffffa3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004D;
  border: 1px solid #FFFFFF;
  border-radius: 9px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}




.colortools .color-tool{
    height: 45px;
    width: 45px;
    position: relative;
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin: 10px;
    box-shadow: 0px 3px 6px #0000004D;
    border: 2px solid #000000;
    border-radius: 9px;
}


.colortools .color-tool.active::after {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(20deg);
  content: "";
  height: 20px;
  width: 20px;
  background: rgb(255 255 255 / 77%);
  border-radius: 20px;
}

.toolbaronright p{
  color: #000;
  font-weight: 600;
  padding: 0 10px;
  margin-bottom: 0.1em;
}

.pdfbackandnext.pdfbackandnextforedit{
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 90%;
    margin: 0 auto;
}

.pdfbackandnext.pdfbackandnextforedit button{
  margin: 0 !important;
  background-color: #1b2429 !important;
  border: none !important;
  font-family: 'Montserrat', sans-serif !important;
}

.backgtable.editpdfpage{
  background-color: transparent;
  border-radius: 0;
  box-shadow:none;
}

.toolbarmain.editpdfpage{
  border: none;
}

.editpdfpage .toolbar{
  border: none;
}

.colortools{
  display: flex;
  flex-wrap: wrap;
}

.othertools{
  display: flex;
  flex-wrap: wrap;
}

.othertools .tool button{
  height: 45px;
  width: 45px;
  position: relative;
  border: 0;
  cursor: pointer;
  display: grid;
  place-content: center;
  margin: 10px;
  box-shadow: 0px 3px 6px #0000004D;
  border: none;
  border-radius: 9px;
  backdrop-filter: blur(50px);
  background-color: #ffffff9c;
}
.othertools .tool button.active{
background-color: #0bd8ff;
} 

.othertools .tool i{
  display: block;
  height: 20px;
  width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.imagetool{
  background-image: url(../assets/svg1/imgtool.svg) !important;
}
.rectool{
  background-image: url(../assets/svg1/Rectangletool.svg) !important;
}
.arrowtool{
  background-image: url(../assets/svg1/arrowtool.svg) !important;
}
.texttool{
  background-image: url(../assets/svg1/texttool.svg) !important;
}
.penciltool{
  background-image: url(../assets/svg1/Pentool.svg) !important; 
}
.freehandtool{
  background-image: url(../assets/svg1/freehandtool.svg) !important; 
}
.highlighttool{
  background-image: url(../assets/svg1/highlighttool.svg) !important; 
}

.editpdfsizes .tool label{
  font-weight: 600;
  color: #000;
  width: 60%;
  margin: 0 !important;
}

.editpdfsizes .tool{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px; 
}

.editpdfsizes .tool .form-control{
  width: 20% !important;
  text-align: center;
  padding: 0 !important;
}

.tiem-list:hover li::after, .tiem-list:hover li::before{
  content: none;
}

.submitpdfbtn{
  background-color: #000 !important;
    border: none !important;
    border-radius: 6px !important;
    padding: 8px 12px !important;
    font-family: 'Montserrat', sans-serif !important; 
}

.clearpdfbtn{
  background-color: #FF0000 !important;
  border: none !important;
  border-radius: 6px !important;
  padding: 8px 12px !important;
  font-family: 'Montserrat', sans-serif !important; 
}

.dltpdfbtn{
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
border: 1px solid var(--unnamed-color-ffffff);
background: #FFFFFF 0% 0% no-repeat padding-box !important;
box-shadow: 0px 3px 6px #0000004D !important; 
border: 1px solid #FFFFFF !important;
border-radius: 9px !important;
opacity: 1 !important;
backdrop-filter: blur(50px) !important;
-webkit-backdrop-filter: blur(50px) !important;
}

.dltpdfbtn svg{  
color: red;
    opacity: 0.6;
    font-weight: 400 !important;
}

.undoredo a:hover{
  box-shadow: none !important;
}

.undoredo a{
    width: 35px;
    display: grid;
    padding-right: 5px;
    border-right: 2px solid #686868;;
    place-items: center;
    height: 35px;
}

.undoredo a:last-child{
  border-right: 1px solid transparent !important;
  padding-right: 0px;
}

.toolbarmain.editpdfpage .toolbar {
  gap: 3%;
  justify-content: flex-end;
  width: 40%;
  padding: 0 10px;
}

.toolbarmain.editpdfpage .toolbar.toolbarhalf{
  justify-content: space-between !important;
  width: 62%;
}

.toolbaredittext{
    color: #000;
    opacity: 1;
    padding: 0 10px ;
    width: 195px;
    font-weight: 600;
}


.pdfeditortable .css-1rki8sg-MuiListItem-root{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.pdfeditortable .MuiDataGrid-columnHeader{
  border: none;
}

.MuiDataGrid-columnHeaderTitle{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
  color: #656565;
  font-size: 15px;
}


.pdfeditortable .bordernone .MuiDataGrid-columnHeaders{
  background-color: #EFF1FD !important;
}

.pdfeditortable .css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone{
  background-color: #EFF1FD !important;
}


.edittableneed{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.addsopbtn.editdwnbtn {
  padding: 5px 60px;
    padding-top: 5px;
    padding-right: 60px;
    padding-bottom: 5px;
    padding-left: 60px;
  max-width: 200px;
  font-size: 15px !important;
  border-radius: 20px;
}

.MuiDataGrid-columnHeaderDraggableContainer{
padding: 0 20px !important;
}

.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell{
  padding: 0 30px !important;
}

.black_button {
  background: #1b2429;
  margin: 2% 2% 0 0;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  max-width: 121px;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.top_button {
  display: flex;
    justify-content: start;
    background-color: #fff;
    padding: 15px;
}
.gray_button {
  background: #9f9a9a;
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  max-width: 121px;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.quize_profile_pic {
  border-radius: 159px;
  margin: 37px;
  padding: 20px;
}

.quiz_text h4 {
  color: #000000;
}
.quiz_text h6 {
  color: #000000;
}
.quiz_text h2 {
  color: #000000;
}
.checked_text {
  text-align: center;
    margin: auto;
    color: #5cb85c !important;
}
.bt_pagination .pagination {
  justify-content: right !important;
  padding: 15px; 
} 

.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  overflow: visible !important;
  white-space: normal !important;
  word-break: break-all;
}
.auto-height-header .MuiDataGrid-columnHeaderTitle {
  white-space: break-spaces !important;
  line-height: 1 !important;
}
.autoheight_table .MuiDataGrid-cell--textLeft {
  padding-left: 30px !important;
}
.table_icon {
  font-size: 18px !important;
  padding: 5px;
}
.color_red {
  color: red
}


.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
}

.custom_switch .switch input {display:none;}

.custom_switch .ch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: .4s;
  transition: .4s;
   border-radius: 34px;
}

.custom_switch .ch_slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.custom_switch input:checked + .ch_slider {
  background-color: #2ab934;
}

.custom_switch input:focus + .ch_slider {
  box-shadow: 0 0 1px #2196F3;
}

.custom_switch input:checked + .ch_slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(30px);
}

/*------ ADDED CSS ---------*/
.custom_switch .ch_slider:after
{
 content:'OFF';
 color: white;
 display: block;
 position: absolute;
 transform: translate(-50%,-50%);
 top: 50%;
 left: 64%;
 font-size: 10px;
 font-family: Verdana, sans-serif;
}

.custom_switch input:checked + .ch_slider:after
{  
  content:'ON';
  left: 20px;
}


/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .dot-pulse-text {
  padding-right: 15px;
 }
 .dot-pulse-container {
  width: 50px;
 }
 .dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}